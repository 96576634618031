<template>
  <div class="account-page">
    <div class="box-title">
      <div class="navbar-link">
        <a :class="{ active: tabsActive === 'live' }" @click="tabsActive = 'live'">
          <div class="text">Live</div>
        </a>
      </div>
      <div class="navbar-link">
        <a :class="{ active: tabsActive === 'demo' }" @click="tabsActive = 'demo'">
          <div class="text">Demo</div>
        </a>
      </div>
    </div>
    <div class="content-box">
      <component :is="tabsActive" :key="`box-profile-${tabsActive}`" :ref="tabsActive"> </component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import live from '@/components/account/Live.vue';
import demo from '@/components/account/Demo.vue';

export default {
  components: { live, demo },
  data() {
    return {
      tabsActive: 'demo',
    };
  },
  computed: {
    ...mapGetters({
      Live: 'account/Live',
    }),
  },
  methods: {
    setTabActive() {
      this.$cookies.set('eggsbook_account', this.tabsActive);
    },
    getTabActive() {
      const tab = this.$cookies.get('eggsbook_account');
      if (tab) this.tabsActive = tab;
    },
  },
  watch: {
    tabsActive: {
      handler() {
        this.setTabActive();
      },
    },
    Live: {
      handler(newVal) {
        const tab = this.$cookies.get('eggsbook_account');
        if (newVal.length && !tab) {
          this.tabsActive = 'live';
        }
      },
    },
  },
  mounted() {
    this.getTabActive();
  },
  created() {
    this.$store.dispatch('wallet/req_getListCoin');
    // this.$store.dispatch('account/req_getListSub');
    this.$store.dispatch('account/req_getTypeSub');
  },
};
</script>

<style lang="scss">
.account-page {
  width: 100%;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 0;
    min-height: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    .navbar-link {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        a {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        &.active {
          border-bottom: 3px solid #009751;
          background: linear-gradient(89deg, #00975146, #8ec63f75);
        }

        .text {
          color: #23222f;
          font-weight: 600;
          font-size: 20px;
          text-transform: uppercase;
        }
        .icon {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  .content-box {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 0;
  }
}
</style>
